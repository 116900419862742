<template>
  <div>
    <div
      class="text-h4 pb-3"
      v-text="$t('Pages.Contracts.contracts')"
    />
    <p class="subtitle">
      {{ $t('Pages.Contracts.first_p') }}
    </p>
    <div class="row row--dense mb-4">
      <div class="col-sm-6 col-lg-4 col-12">
        <v-sheet
          outlined
          color="transparent"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
                color="info"
                size="36"
              >
                <span class="white--text text-h5">1</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Pages.Contracts.info_t1') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('Pages.Contracts.info_t1_text') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </div>
      <div class="col-sm-6 col-lg-4 col-12">
        <v-sheet
          outlined
          color="transparent"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
                color="info"
                size="36"
              >
                <span class="white--text text-h5">2</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Pages.Contracts.info_t2') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('Pages.Contracts.info_t2_text') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </div>
      <div class="col-sm-6 col-lg-4 col-12">
        <v-sheet
          outlined
          color="transparent"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
                color="info"
                size="36"
              >
                <span class="white--text text-h5">3</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Pages.Contracts.info_t3') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('Pages.Contracts.info_t3_text') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </div>
    </div>
    <!-- <v-alert type="info">
      <p class="subtitle">
        GigPort erbjuder digital signering av avtal mha Kivra
      </p>

      <ul class="pb-4">
        <li>Hitta den kompetens och konsult du söker och kom överens om uppdraget</li>
        <li>På konsultens profilsida hittar ni en Skapa Avtal-knapp</li>
        <li>Ladda upp ert avtal, pdf max</li>
        <li>Båda parterna signerar digitalt via Kivra</li>
        <li>Era avtal hittar ni sedan här (länk till Avtal)</li>
      </ul>
      <p class="mb-0">
        Lycka till!
      </p>
    </v-alert> -->
    <v-card
      color="neutral"
      class="rounded-xl"
    >
      <v-data-table
        :headers="headers"
        :items="contracts"
        sort-by="calories"
        class="elevation-1 neutral"
        :loading="isLoading"
      >
        <template #item?.kivraContract.parties[0]="{ item }">
          {{ item.kivraContract.parties[0].name }}
        </template>
        <template #item?.kivraContract.parties[1]="{ item }">
          {{ item.kivraContract.parties[1].name }}
        </template>
        <template #item?.createdAt="{ item }">
          {{ formatDate.full(item.createdAt) }}
        </template>
        <template #item.kivraContract.original="{ item }">
          <a
            @click="downloadContract(item.kivraContract)"
          >{{ $t('Common.download') }}</a>
        </template>
        <!-- <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
            >
              <v-icon>
                mdi-pen
              </v-icon>
            </v-btn>
          </template>
          <span>Sign</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
            >
              <v-icon>
                mdi-file
              </v-icon>
            </v-btn>
          </template>
          <span>View file</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Delete file</span>
        </v-tooltip>
      </template> -->

        <template #no-data>
          No contracts
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { formatDate } from '@/config/formatDate.js'
export default {
  name: 'ContractsTable',
  components: {
  },
  props: {
    contracts: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formatDate,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      permissions: []
    },
    defaultItem: {
      permissions: ['admin']
    }
  }),
  computed: {
    headers () {
      return [
        {
          text: this.$t('Pages.Contracts.contract_name'),
          align: 'start',
          value: 'kivraContract.subject'
        },
        { text: this.$t('Pages.Contracts.signer'), value: 'kivraContract.parties[0].name' },
        { text: this.$t('Pages.Contracts.delegate'), value: 'kivraContract.parties[1].name' },
        { text: this.$t('Pages.Contracts.date_modified'), value: 'createdAt', sortable: false },
        { text: this.$t('Pages.Contracts.status'), value: 'kivraContract.status' },
        { text: '', value: 'kivraContract.original' },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },

  methods: {
    downloadContract (kivraContract) {
      const linkSource = `data:application/pdf;base64,${kivraContract.original.data}`
      const downloadLink = document.createElement('a')
      const fileName = kivraContract.subject + '.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }
}
</script>
