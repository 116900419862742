<template>
  <section
    id="contracts"
  >
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col
          cols="12"
        >
          <ContractsTable
            :contracts="contracts"
            :is-loading="isLoading"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { GET_CONTRACTS } from '@/store/actions.type'
import ContractsTable from './ContractsTable.vue'
import { mapState } from 'vuex'
export default {
  name: 'Contracts',
  components: { ContractsTable },
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  data: () => ({
    brandName: 'Contracts',
    isLoading: false
  }),
  computed: {
    ...mapState(['contracts'])
  },
  mounted () {
    this.getContracts()
  },
  methods: {
    getContracts () {
      this.isLoading = true
      this.$store.dispatch(GET_CONTRACTS).then(() => {
        this.isLoading = false
      }).catch((error) => {
        this.$root.$emit('errorMessage', error)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
